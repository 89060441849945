<template>
  <el-dialog title="同步成品信息" :visible.sync="syncDialogVisible" width="80%" :before-close="closeSyncDialog" top="0">
    <div slot="title" style="font-size: 22px">
      <span>同步成品信息</span>
      <span style="color: red">( 所有填入搜索条件必须精准！)</span>
    </div>
    <el-card v-sticky class="vg_mb_8">
      <el-form :model="syncForm" label-width="120px">
        <el-row>
          <el-col :md="8">
            <el-form-item label="客户货号:" prop="prod_cust_no">
              <el-input size="small" v-model.trim="syncForm.prod_cust_no" clearable placeholder="请填写客户货号" />
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="我司货号:" prop="prod_cust_no">
              <el-input size="small" v-model.trim="syncForm.prod_no" clearable placeholder="请填写我司货号" />
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="中文名称:" prop="prod_cust_no">
              <el-input size="small" v-model.trim="syncForm.prod_name" clearable placeholder="请填写中文名称" />
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="英文名称:" prop="prod_cust_no">
              <el-input size="small" v-model.trim="syncForm.prod_ename" clearable placeholder="请填写英文名称" />
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="客户简称:" prop="prod_cust_no">
              <el-input size="small" v-model.trim="syncForm.cust_abbr" clearable placeholder="请填写客户货号" />
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="规格:" prop="prod_cust_no">
              <el-input size="small" v-model.trim="syncForm.prod_spec" clearable placeholder="请填写规格" />
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="录入人:" prop="prod_cust_no">
              <el-input size="small" v-model.trim="syncForm.input_name" clearable placeholder="请填写录入人" />
            </el-form-item>
          </el-col>
          <el-col :md="16">
            <div style="width: 50%">
              <el-form-item label="录入时间:">
                <el-date-picker v-model="timeValue" size="small" type="date" placeholder="请选择日期" />
              </el-form-item>
            </div>
          </el-col>
          <el-col :md="24" style="display: flex">
            <el-form-item label-width="0">
              <el-button size="small" type="primary" icon="el-icon-search" class="vg_ml_16" @click="getSyncData()">查询</el-button>
              <el-button size="small" type="info" icon="el-icon-refresh" class="vg_ml_16" @click="clearSearch()">刷新</el-button>
              <!--              <el-button size="small" type="warning" icon="el-icon-loading" class="vg_ml_16" @click="showSelectedData()">
                提取至待同步区
              </el-button>-->
              <el-button class="vg_ml_16" icon="el-icon-document-copy" size="small" type="success" @click="syncSelectedData">同步已选内容</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!--数据表格-->
    <el-table ref="multipleTable" v-loading="syncLoadFlag" :data="syncTableData" border stripe @selection-change="val => handleSelectionChange(val, 1)">
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="商品/部件" align="center" min-width="50">
        <template v-slot="scope">
          <el-radio-group v-model="scope.row.prod_attr" size="mini">
            <el-radio-button label="商品" />
            <el-radio-button label="部件" />
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column property="prod_cust_no" label="客户货号" align="center" min-width="35" />
      <el-table-column property="prod_no" label="我司货号" align="center" />
      <el-table-column property="prod_name" label="中文名称" align="center" />
      <el-table-column property="prod_ename" label="英文名称" align="center" min-width="155" />
      <el-table-column property="cust_abbr" label="客户简称" align="center" />
      <el-table-column property="prod_spec" label="规格" align="center" min-width="30" />
      <el-table-column property="input_name" label="录入人" align="center" min-width="30" />
      <el-table-column property="input_date" label="录入时间" align="center" min-width="50" />
    </el-table>
    <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination" />
  </el-dialog>
</template>

<script>
import { prodAPI } from '@api/modules/prod';
import { getDateNoTime, handleSpecialTime } from '@assets/js/dateUtils';
import { cloneDeep } from 'lodash';
import PubPagination from '@/components/common/pubPagination.vue';

export default {
  name: 'ProdSyncDialog',
  components: { PubPagination },
  props: {
    syncDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      syncLoadFlag: false,
      timeValue: null,
      syncForm: {},
      syncTableData: [],
      multiSelection: [],
      selectedMultiSelection: [],
      selectedTableData: [],
      isShowSelected: false,
      totalPage: 0,
      currentPage: 1
    };
  },
  methods: {
    /**
     * 处理特殊时间
     */
    handleSpecialTime,
    /**
     * 关闭弹窗
     */
    closeSyncDialog() {
      this.$emit('closeSyncDialog');
    },
    /**
     * 获取数据
     */
    async getSyncData() {
      await (this.syncLoadFlag = true);
      this.syncForm.page_no = this.currentPage;
      this.syncForm.input_date = getDateNoTime(this.timeValue);
      prodAPI.getProductList(this.syncForm).then(({ data }) => {
        this.syncTableData = data.form;
        this.totalPage = data.total;
        this.syncLoadFlag = false;
      });
    },
    /**
     * 清空搜索框重新获取数据
     */
    clearSearch() {
      this.timeValue = null;
      this.syncForm = {};
      this.getSyncData();
    },
    /**
     * 选中数据复制
     * @param val 行数据
     * @param index 下标
     */
    handleSelectionChange(val, index) {
      if (index === 0) {
        this.selectedMultiSelection = val;
      } else {
        this.multiSelection = val;
      }
    },
    /**
     * 选中数据添加至待选区并选中
     */
    showSelectedData() {
      this.isShowSelected = true;
      this.multiSelection.forEach(row => {
        if (!this.selectedTableData.find(x => x === row)) {
          let tempRow = cloneDeep(row);
          this.selectedTableData.push(tempRow);
          this.$refs.selectedMultipleTable.toggleRowSelection(tempRow);
        }
        this.$refs.multipleTable.clearSelection();
      });
    },
    /**
     * 同步已选择数据
     */
    syncSelectedData() {
      if (this.multiSelection.length !== 1) return this.$message.warning('请选择一条数据!');
      if (this.multiSelection.some(x => !x.prod_attr)) return this.$message.warning('请选择商品或部件');
      let submitData = this.multiSelection[0];
      submitData.properties = submitData.prod_attr === '商品' ? 1 : 2;
      prodAPI.addProductByMessage(submitData).then(({ data }) => {
        this.multiSelection = [];
        this.selectedTableData = [];
        this.isShowSelected = false;
        this.closeSyncDialog();
        if (submitData.properties === 2) data.prod_part_list = [];
        data.prod_part_list.forEach(item => (item.supp_abbr = Number(item.supp_abbr)));
        this.$emit('submitSuccess', data);
      });
    },
    changePageSearch(val) {
      this.currentPage = val;
      this.getSyncData();
    }
  }
};
</script>

<style scoped>
/deep/.el-dialog__body {
  padding: 0 20px !important;
}
</style>
